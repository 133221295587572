import React from "react"
import SEO from "../../../../../components/seo"
import NoheaderFooter from "../../../../../components/Noheaderfooter"
import InvestmentsMobile from "../../../../../components/body/pages/mobile/ng/legal/disclaimer/investments"

const DisclaimerInvestments = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/disclaimer/investments"}
      title="Disclaimer Investments | Kuda | The Money App for Africans"
    />
    <InvestmentsMobile />
  </NoheaderFooter>
)

export default DisclaimerInvestments
